import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { HiExclamation, HiOutlineX, HiCheck, HiOutlinePlus, HiSearch, HiOutlineFilter, HiBookmark, HiUpload, HiInformationCircle } from "react-icons/hi";
import { FaRegSave } from "react-icons/fa";
import { toast } from "react-hot-toast";

import API from "../api";

const FILTER_CATEGORIES = [
  {
    id: "code",
    label: "Codes",
    value: "@codes:",
    subFilters: [
      { id: "all", label: "" },
      { id: "added", label: "ajouté", value: "@codes>added:" },
      { id: "deleted", label: "supprimé", value: "@codes>deleted:" },
      { id: "handwritten", label: "manuscrit", value: "@codes>handwritten:" },
      { id: "not", label: "non présent", value: "@codes>not:" },
      { id: "notInserted", label: "refusé", value: "@codes>notInserted:" },
    ],
  },
  { id: "text", label: "Texte", value: "@text:" },
  { id: "rpps", label: "RPPS", value: "@RPPS:" },
  { id: "adeli", label: "ADELI", value: "@ADELI:" },
  {
    id: "doctor",
    label: "Code médecin",
    value: "@doctorStatus:",
    options: [
      { value: ".*", label: "Tous" },
      { value: "NO_DOCTOR", label: "Aucun médecin détecté" },
      { value: "NOT_FOUND", label: "Non trouvé" },
      { value: "TOO_MANY_RESULTS", label: "Plusieurs résultats" },
      { value: "FOUND", label: "Inséré" },
      { value: "DELETED", label: "Supprimé" },
    ],
  },
  {
    id: "warning",
    label: "Alertes",
    value: "@warning:",
    options: [
      { value: ".*", label: "Toutes" },
      { value: "isHandwritten", label: "Texte manuscrit" },
      { value: "hasExamWithoutCode", label: "Examen sans code" },
      { value: "hasAdditionalInfo", label: "Infos complémentaires" },
      { value: "hasRenewal", label: "Renouvellement" },
      { value: "hasSchedule", label: "Date de réalisation" },
      { value: "hasConditionNotTakenIntoAccount", label: "Conditions non prises en compte" },
      { value: "hasCrossedOut", label: "Textes barrés" },
    ],
  },
  {
    id: "type",
    label: "Type",
    value: "@type:",
    options: [
      { value: ".*", label: "Tous" },
      { value: "SIL", label: "SIL" },
      { value: "API", label: "API" },
      { value: "HPRIM", label: "HPRIM" },
    ],
  },
];

const Explorer = ({ user }) => {
  const params = new URLSearchParams(window.location.search);
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [filter, setFilter] = useState({
    limit: 200,
    date: params.get("q")?.startsWith("@") || !params.get("q") ? new Date().toISOString().slice(0, 10) : "",
    codeDeleted: params.get("codeDeleted") === "true",
    codeAdded: params.get("codeAdded") === "true",
    codeAddedWithoutWarning: params.get("codeAddedWithoutWarning") === "true",
    codeDeletedWithoutWarning: params.get("codeDeletedWithoutWarning") === "true",
    q: params.get("q") || "",
  });
  const [debouncedFilter, setDebouncedFilter] = useState(filter);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);

  let isSuperAdmin = user.role === "superadmin";

  const displayPolygons = useCallback(async () => {
    if (!selectedPrescription) return;

    const imageElement = document.getElementById("displayImage");
    if (!imageElement) return;

    setPolygons([]);

    for (const act of selectedPrescription.acts) {
      if (!act.polygon.length) continue;

      const adjustedPoints = act.polygon.map((point) => ({
        x: point.x * imageElement.clientWidth,
        y: point.y * imageElement.clientHeight,
      }));

      const pointsString = adjustedPoints.map((point) => `${point.x}px ${point.y}px`).join(", ");

      const className = `querco_polygon_${act._id}`;
      setPolygons((prev) => {
        const existingPolygon = prev.find((polygon) => polygon.pointsString === pointsString);
        if (existingPolygon) {
          existingPolygon.className += ` ${className}`;
          existingPolygon.ALD = existingPolygon.ALD || act.ALD;
          existingPolygon.isDeleted = existingPolygon.isDeleted || (act.isDeleted && act.codesPredicted.length !== 0);
          existingPolygon.codeNotFound = existingPolygon.codeNotFound || act.codesPredicted.length === 0;
          existingPolygon.codeNotInserted = existingPolygon.codeNotInserted || (act.notInserted && act.codesPredicted.length !== 0);

          return prev;
        }

        return [
          ...prev,
          {
            pointsString,
            className,
            ALD: act.ALD,
            isDeleted: act.isDeleted && act.codesPredicted?.length !== 0,
            codeNotFound: act.codesPredicted.length === 0,
            codeNotInserted: act.notInserted && act.codesPredicted.length !== 0,
          },
        ];
      });
    }
  }, [selectedPrescription?._id]);

  const getData = async () => {
    const toastId = toast.loading("Chargement des données...");
    try {
      const response = await API.get(
        `/prescription/explorer?limit=${debouncedFilter.limit}&date=${debouncedFilter.date}&codeDeleted=${debouncedFilter.codeDeleted}&codeAdded=${debouncedFilter.codeAdded}&codeAddedWithoutWarning=${debouncedFilter.codeAddedWithoutWarning}&codeDeletedWithoutWarning=${debouncedFilter.codeDeletedWithoutWarning}&q=${debouncedFilter.q}&flagged=${debouncedFilter.flagged}`
      );
      if (!response.ok) return toast.error(response.message, { id: toastId });

      toast.success("Données chargées", { id: toastId });
      setPrescriptions(response.data);

      if (selectedPrescription) return;

      const selectedPrescriptionId = params.get("selectedPrescription");
      if (selectedPrescriptionId) setSelectedPrescription(response.data.find((p) => p._id === selectedPrescriptionId));
      else setSelectedPrescription(response.data[0]);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  useEffect(() => {
    getData();
  }, [debouncedFilter]);

  useEffect(() => {
    displayPolygons();
  }, [selectedPrescription?._id]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedFilter(filter);
      params.set("q", filter.q);
      window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [filter]);

  const flagPrescription = async (prescription, flag) => {
    const toastId = toast.loading("Signalement...");
    try {
      const response = await API.put(`/prescription/${prescription._id}/flag`, { flag });
      if (!response.ok) return toast.error(response.message, { id: toastId });

      toast.success("Dossier signalé", { id: toastId });
      if (prescription._id === selectedPrescription._id) setSelectedPrescription((prev) => ({ ...prev, flags: response.data.flags }));
      await getData();
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  const newExtraction = async (files, formMode) => {
    if (!files) return;

    // Convert to array if single file was passed
    const filesArray = Array.isArray(files) ? files : [files];
    if (filesArray.length === 0) return;

    const toastId = toast.loading(`Extraction en cours... (0/${filesArray.length})`);

    try {
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];

        // Update toast message with progress
        toast.loading(`Extraction en cours... (${i + 1}/${filesArray.length})`, { id: toastId });

        const formData = new FormData();
        formData.append("file", file);
        formData.append("formMode", formMode);

        const response = await API.postFormData("/v1/prescription", formData);

        if (!response.ok) throw new Error(response.message);

        if (filesArray.length === 1) return (window.location.href = `/explorer?q=${response.data._id}`);
      }

      window.location.href = `/explorer`;
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue", { id: toastId });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        const currentIndex = prescriptions.findIndex((p) => p._id === selectedPrescription?._id);
        if (currentIndex !== -1) {
          const nextIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;
          if (nextIndex >= 0 && nextIndex < prescriptions.length) {
            setSelectedPrescription(prescriptions[nextIndex]);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [prescriptions, selectedPrescription]);

  return (
    <div className="px-4">
      <div className="flex gap-2">
        <div className="relative mb-2 flex-grow">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <HiSearch className="h-5 w-5 text-gray-400" />
          </div>
          <input
            id="searchInput"
            type="text"
            value={filter.q}
            placeholder="Rechercher"
            className="w-full pl-10 pr-24 p-2 border rounded-md"
            onChange={(e) => setFilter({ ...filter, q: e.target.value })}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            {filter.q && (
              <button className="pr-2 flex items-center text-gray-400 hover:text-gray-600" onClick={() => setFilter({ ...filter, q: "" })}>
                <HiOutlineX className="h-5 w-5" />
              </button>
            )}
            <button className="pr-3 flex items-center text-[#026D77] hover:text-[#015961]" onClick={() => setIsFilterPopupOpen(true)} title="Filtres avancés">
              <HiOutlineFilter className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="relative flex items-center gap-2 mb-2">
          <input
            type="date"
            id="dateFilter"
            className="p-2 border rounded-md pr-8"
            value={filter.date}
            max={new Date().toISOString().slice(0, 10)}
            onChange={(e) => setFilter({ ...filter, date: e.target.value })}
          />
          {filter.date && (
            <button className="absolute right-0 mr-2 text-gray-400 hover:text-gray-600" onClick={() => setFilter({ ...filter, date: "" })} aria-label="Clear date">
              <HiOutlineX className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>

      {isFilterPopupOpen && <FilterPopup onClose={() => setIsFilterPopupOpen(false)} onApply={(query) => setFilter({ ...filter, q: query })} initialQuery={filter.q} />}
      {isUploadPopupOpen && <FileUploadPopup onClose={() => setIsUploadPopupOpen(false)} onUpload={newExtraction} />}

      <div className="grid grid-cols-12">
        <div className="col-span-2 h-[calc(100vh-190px)]">
          <div className="flex items-center mt-4 gap-2 justify-between">
            <div className="text-xl font-bold">Ordonnances</div>
            <button type="button" className="text-[#026D77] text-xl flex items-center" onClick={() => setIsUploadPopupOpen(true)} title="Télécharger une ordonnance">
              <HiOutlinePlus />
            </button>
          </div>
          <div className="bg-white overflow-auto max-h-[calc(100vh-200px)]">
            {prescriptions.map((prescription) => (
              <button
                key={prescription._id}
                className={`w-full text-left p-2 border-t border-gray-200 ${
                  selectedPrescription && selectedPrescription._id === prescription._id ? "bg-[#026D77] text-white rounded-md" : ""
                }`}
                onClick={() => setSelectedPrescription(prescription)}
              >
                {new Date(prescription.createdAt).toLocaleString().slice(0, 5)} - {prescription.silId || prescription.type}
              </button>
            ))}
            <div className="w-full flex justify-center py-4 border-t border-gray-200">
              <button
                disabled={prescriptions.length <= filter.limit}
                className={`p-2 rounded-md border border-[#026D77] text-[#026D77] ${prescriptions.length <= filter.limit ? "opacity-50" : ""}`}
                onClick={() => setFilter({ ...filter, limit: filter.limit + 100 })}
              >
                Charger plus d'ordonnances
              </button>
            </div>
          </div>
        </div>
        {selectedPrescription && (
          <>
            <div className="col-span-6 flex justify-center">
              <div className="relative">
                <img src={selectedPrescription.img} alt="Ordonnance" className="max-h-[calc(100vh-190px)]" id="displayImage" onLoad={displayPolygons} />
                {polygons &&
                  polygons.map((polygon, index) => (
                    <div
                      key={index}
                      className={`absolute top-0 left-0 w-full h-full z-[220] opacity-25 ${polygon.className} ${
                        polygon.isDeleted
                          ? "bg-red-500"
                          : polygon.codeNotFound
                          ? "bg-orange-500"
                          : polygon.codeNotInserted
                          ? "bg-blue-500"
                          : polygon.ALD
                          ? "bg-purple-500"
                          : "bg-green-500"
                      }`}
                      style={{ clipPath: `polygon(${polygon.pointsString})` }}
                      mongoid={polygon.mongoid}
                    ></div>
                  ))}
              </div>
            </div>

            <div className=" col-span-4 overflow-auto h-[calc(100vh-190px)] pb-4">
              <div className="mt-4">{new Date(selectedPrescription.createdAt).toLocaleString().slice(0, 10)}</div>
              <h3 className="text-2xl font-bold mb-4">{selectedPrescription.silId}</h3>

              {Object.values(selectedPrescription.warning).some((el) => el === true) && (
                <div className="mb-4 border-2 border-orange-500 bg-orange-100 p-2 rounded-md">
                  <div className="font-bold text-orange-500">Vérifications</div>
                  <div>
                    {selectedPrescription.warning.isHandwritten && <div>- Vérifier l'intégration des textes manuscrits</div>}
                    {selectedPrescription.warning.hasExamWithoutCode && <div>- Intégrer les éventuelles analyses manquantes (surlignées en rouge ou non surlignées) </div>}
                    {selectedPrescription.warning.hasAdditionalInfo && <div>- Infos complémentaires (urgent, transmission, renouvellement, date de réalisation ...)</div>}
                    {selectedPrescription.warning.hasRenewal && <div>- Renouvellement</div>}
                    {selectedPrescription.warning.hasSchedule && <div>- Date de réalisation</div>}
                    {selectedPrescription.warning.hasConditionNotTakenIntoAccount && <div>- Que les conditions (si, +/-) soient respectées</div>}
                    {selectedPrescription.warning.hasCrossedOut && <div>- Textes barrés</div>}
                  </div>
                </div>
              )}

              <div>
                <span className="font-bold">Date : </span> {selectedPrescription.date}
              </div>
              <div>
                <span className="font-bold">Prescripteur : </span>
                {selectedPrescription.doctor?.RPPS || "N/A"} | {selectedPrescription.doctor?.ADELI || "N/A"}
              </div>
              {selectedPrescription.doctor?.status && (
                <div>
                  <span className="font-bold">Code prescripteur : </span>
                  {selectedPrescription.doctor?.code || "N/A"}
                  <span className="ml-2">
                    {selectedPrescription.doctor.status === "FOUND" && <span className="text-green-600">✓</span>}
                    {selectedPrescription.doctor.status === "NOT_FOUND" && <span className="text-orange-500">(Non trouvé)</span>}
                    {selectedPrescription.doctor.status === "TOO_MANY_RESULTS" && <span className="text-yellow-600">(Plusieurs résultats)</span>}
                    {selectedPrescription.doctor.status === "NO_DOCTOR" && <span className="text-red-500">(Aucun médecin détecté)</span>}
                    {selectedPrescription.doctor.status === "DELETED" && <span className="text-red-600">(Supprimé)</span>}
                    {selectedPrescription.doctor.status === "ERROR" && <span className="text-blue-500">(Erreur)</span>}
                  </span>
                </div>
              )}
              <div>
                <span className="font-bold">Examens :</span>
                {selectedPrescription.acts.map((act, index) => (
                  <span
                    key={index}
                    className={`border-2 inline-block m-1 py-0.25 px-0.5 rounded-md ${
                      act.codes.length > 0 && act.codesPredicted.length === 0
                        ? "border-yellow-500 bg-yellow-100"
                        : act.isDeleted && act.codesPredicted.length !== 0
                        ? "border-red-500 bg-red-100"
                        : act.codes.length === 0
                        ? "border-orange-500 bg-orange-100"
                        : act.notInserted && act.codesPredicted.length !== 0
                        ? "border-blue-500 bg-blue-100"
                        : act.ALD
                        ? "border-purple-500 bg-purple-100"
                        : "border-green-500 bg-green-100"
                    }`}
                    onMouseEnter={() => {
                      const codeElement = document.querySelectorAll(`.querco_polygon_${act._id}`);
                      if (codeElement) codeElement.forEach((element) => (element.style.opacity = 0.75));
                    }}
                    onMouseLeave={() => {
                      const codeElement = document.querySelectorAll(`.querco_polygon_${act._id}`);
                      if (codeElement) codeElement.forEach((element) => (element.style.opacity = 0.25));
                    }}
                  >
                    {act.text || "N/A"} : {act.codes.join(", ") || act.codesPredicted.join(", ") || "N/A"}
                  </span>
                ))}
              </div>

              <div className="flex justify-center gap-4 mt-16 2xl:flex-row 2xl:w-full flex-col w-fit">
                <button
                  className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                    selectedPrescription.flags?.includes("MISSING_EXAM") ? "text-white bg-red-500" : "text-red-500 bg-red-100"
                  }`}
                  onClick={() => flagPrescription(selectedPrescription, "MISSING_EXAM")}
                >
                  <HiExclamation />
                  {selectedPrescription.flags?.includes("MISSING_EXAM") ? "Examen manquant signalé" : "Signaler un examen non-souligné"}
                </button>
                <button
                  className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                    selectedPrescription.flags?.includes("TRADUCTION_ERROR") ? "text-white bg-red-500" : "text-red-500 bg-red-100"
                  }`}
                  onClick={() => flagPrescription(selectedPrescription, "TRADUCTION_ERROR")}
                >
                  <HiExclamation />
                  {selectedPrescription.flags?.includes("TRADUCTION_ERROR") ? "Erreur de traduction signalée" : "Signaler une erreur de traduction"}
                </button>
                {isSuperAdmin && (
                  <button
                    className={`px-2 py-1 rounded-md flex items-center gap-2 ${
                      selectedPrescription.flags?.includes("OK") ? "text-white bg-green-500" : "text-green-500 bg-green-100"
                    }`}
                    onClick={() => flagPrescription(selectedPrescription, "OK")}
                  >
                    <HiCheck />
                    OK
                  </button>
                )}
              </div>
              {isSuperAdmin && selectedPrescription?.hprim?.data && (
                <div>
                  <div className="text-xl font-bold mt-8">HPRIM</div>
                  <pre className="whitespace-pre overflow-x-auto font-mono">{selectedPrescription?.hprim?.data}</pre>
                </div>
              )}
              {isSuperAdmin && (
                <div>
                  <div className="text-xl font-bold mt-8">NER</div>
                  {selectedPrescription?.ner?.map((ner) => (
                    <div key={ner._id}>
                      <span className="font-bold">{ner.category} : </span> {ner.content}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        <div className="fixed bottom-5 right-5 text-sm">
          <span className="border-2 rounded-md p-1 m-1 border-yellow-500 bg-yellow-100">Code(s) ajouté(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-red-500 bg-red-100">Code(s) supprimé(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-orange-500 bg-orange-100">Code(s) non trouvé(s)</span>
          <span className="border-2 rounded-md p-1 m-1 border-blue-500 bg-blue-100">Code(s) refusé(s) par le SIL</span>
          <span className="border-2 rounded-md p-1 m-1 border-purple-500 bg-purple-100">Code(s) inséré(s) en ALD</span>
          <span className="border-2 rounded-md p-1 m-1 border-green-500 bg-green-100">Code(s) inséré(s)</span>
        </div>
      </div>
    </div>
  );
};

const FileUploadPopup = ({ onClose, onUpload }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [formMode, setFormMode] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [testProgress, setTestProgress] = useState(0);
  const [testTotal, setTestTotal] = useState(0);
  const [isRunningTest, setIsRunningTest] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const fileInputRef = useRef(null);
  const multipleFilesInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    if (testMode) {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // Process multiple files
        handleFilesTest(e.dataTransfer.files);
      } else {
        toast.error("Veuillez déposer des fichiers en mode test");
      }
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        // Process all dropped files, not just the first one
        onUpload(Array.from(e.dataTransfer.files), formMode);
        onClose();
      }
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // Process all selected files, not just the first one
      onUpload(Array.from(e.target.files), formMode);
      onClose();
    }
  };

  const handleMultipleFilesSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFilesTest(e.target.files);
    }
  };

  const handleFilesTest = async (files) => {
    setIsRunningTest(true);
    setTestProgress(0);
    setTestTotal(files.length);

    // Set up data structures for results
    const csvLines = [];
    let changeCount = 0;
    const date = new Date().toISOString();

    // Process each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Skip any file that isn't an image or PDF
      const fileType = file.type.toLowerCase();
      if (!fileType.includes("image") && !fileType.includes("pdf")) {
        console.warn(`Skipping file ${file.name}: not an image or PDF`);
        continue;
      }

      try {
        // Get the original prescription data for comparison
        // Attempt to find by removing the file extension from the filename
        const fileId = file.name.split(".")[0];
        const originalResponse = await API.get(`/v1/prescription/${fileId}`);
        if (!originalResponse.ok) throw new Error("Could not retrieve original prescription");

        // Create a FormData object for the file
        const formData = new FormData();
        formData.append("file", file);
        formData.append("formMode", formMode);
        formData.append("indexName", originalResponse.data.collection);

        // Use the existing API client to make the request
        const response = await API.postFormData("/v1/prescription", formData);
        if (!response.ok) throw new Error(response.message || "API error");

        const originalData = originalResponse.data;
        const newData = response.data;

        // Compare the data similar to test.js
        const actsDiff = compareActs(originalData.acts || [], newData.acts || []);
        const doctorDiff = compareDoctor(originalData.doctor, newData.doctor);
        const dateDiff = originalData.date !== newData.date ? `${originalData.date}->${newData.date}` : "";
        const warningDiff = compareWarnings(originalData.warning, newData.warning);
        const hasChanges = actsDiff || doctorDiff || dateDiff || warningDiff ? "Oui" : "Non";

        if (hasChanges === "Oui") changeCount++;

        // Add to CSV
        const csvLine = `${originalData.externalId} (${file.name}),"${actsDiff.replace(/"/g, '""')}","${doctorDiff.replace(/"/g, '""')}","${dateDiff.replace(
          /"/g,
          '""'
        )}","${warningDiff.replace(/"/g, '""')}","${hasChanges}"`;
        csvLines.push(csvLine);
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        const csvLine = `${file.name},"API Error","API Error","API Error","${error.message}","Non"`;
        csvLines.push(csvLine);
      }

      setTestProgress(i + 1);
    }

    // Generate CSV content
    const title = `${date.replace(/:/g, "-")} - Contrôle Qualité`;
    const dateLine = `Date: ${date}\n`;
    const percentage = ((files.length - changeCount) / files.length) * 100;
    const summaryLine = `Nombre de dossiers sans changements: ${files.length - changeCount} (${percentage.toFixed(2)}%)\n\n`;
    const header = "Dossier,Examens,Docteur,Date,Alertes,Changements\n";
    const csvContent = dateLine + summaryLine + header + csvLines.join("\n");

    // Set test results for download
    setTestResults({
      content: csvContent,
      filename: `${title}.csv`,
    });

    setIsRunningTest(false);
  };

  // Helper functions for comparison, similar to test.js
  const compareActs = (oldActs, newActs) => {
    const differences = [];
    const oldActsWithoutAdded = oldActs.filter((act) => !act.isAdded);

    // Check for missing acts (prefixed with -)
    for (const oldAct of oldActsWithoutAdded) {
      if (!oldAct.codes?.length || oldAct.codes.some((code) => code.startsWith("QUERCO"))) continue;
      const found = newActs.find((newAct) => newAct.codes.length === oldAct.codes.length && newAct.codes.every((code, index) => code === oldAct.codes[index]));
      if (!found) {
        differences.push(`-${oldAct.codes.join(",")}`);
      }
    }

    // Check for new acts (prefixed with +)
    for (const newAct of newActs) {
      if (newAct.codes.some((code) => code.startsWith("QUERCO"))) continue;
      const found = oldActsWithoutAdded.find((oldAct) => oldAct.codes?.length === newAct.codes.length && oldAct.codes?.every((code, index) => code === newAct.codes[index]));
      if (!found) {
        differences.push(`+${newAct.codes.join(",") || "N/A"}`);
      }
    }

    return differences.join("; ");
  };

  const compareDoctor = (oldDoc, newDoc) => {
    const differences = [];

    if (oldDoc?.RPPS !== newDoc?.RPPS) {
      if (!oldDoc?.RPPS) differences.push(`+RPPS:${newDoc?.RPPS}`);
      else if (!newDoc?.RPPS) differences.push(`-RPPS:${oldDoc?.RPPS}`);
      else differences.push(`RPPS:${oldDoc?.RPPS}->${newDoc?.RPPS}`);
    }

    if (oldDoc?.ADELI !== newDoc?.ADELI) {
      if (!oldDoc?.ADELI) differences.push(`+ADELI:${newDoc?.ADELI}`);
      else if (!newDoc?.ADELI) differences.push(`-ADELI:${oldDoc?.ADELI}`);
      else differences.push(`ADELI:${oldDoc?.ADELI}->${newDoc?.ADELI}`);
    }

    return differences.join("; ");
  };

  const compareWarnings = (oldWarnings, newWarnings) => {
    const differences = [];
    for (const key of Object.keys(oldWarnings || {})) {
      if (oldWarnings[key] !== (newWarnings || {})[key]) differences.push(`${key}:${oldWarnings[key]}->${(newWarnings || {})[key]}`);
    }
    return differences.join("; ");
  };

  const downloadCsv = () => {
    if (!testResults) return;

    const blob = new Blob([testResults.content], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", testResults.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[300]" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-[500px] max-h-[80vh]" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Télécharger une ordonnance</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <HiOutlineX className="w-6 h-6" />
          </button>
        </div>

        {!isRunningTest && !testResults ? (
          <>
            <div
              className={`border-2 border-dashed rounded-lg p-8 mb-4 text-center cursor-pointer ${isDragging ? "border-[#026D77] bg-[#026D77]/10" : "border-gray-300"}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={() => {
                if (testMode) {
                  multipleFilesInputRef.current.click();
                } else {
                  fileInputRef.current.click();
                }
              }}
            >
              <input type="file" className="hidden" ref={fileInputRef} onChange={handleFileChange} accept="image/*,application/pdf" multiple />
              <input type="file" className="hidden" ref={multipleFilesInputRef} onChange={handleMultipleFilesSelect} multiple accept="image/*,application/pdf" />
              <HiUpload className="mx-auto h-12 w-12 text-gray-400" />
              <p className="mt-2 text-sm text-gray-600">
                {testMode ? "Glissez-déposez des fichiers ici ou cliquez pour parcourir" : "Glissez-déposez des fichiers ici ou cliquez pour parcourir"}
              </p>
              <p className="text-xs text-gray-500 mt-1">{testMode ? "Sélectionnez plusieurs fichiers pour les tests" : "Formats acceptés: images, PDF"}</p>
            </div>

            <div className="flex flex-col gap-3 mb-4">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input type="checkbox" className="sr-only" checked={formMode} onChange={() => setFormMode(!formMode)} disabled={testMode} />
                  <div className={`block w-10 h-6 rounded-full ${formMode ? "bg-[#026D77]" : "bg-gray-300"} ${testMode ? "opacity-50" : ""}`}></div>
                  <div className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${formMode ? "transform translate-x-4" : ""}`}></div>
                </div>
                <div className="ml-3 text-gray-700 font-medium">Mode formulaire</div>
              </label>

              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input type="checkbox" className="sr-only" checked={testMode} onChange={() => setTestMode(!testMode)} />
                  <div className={`block w-10 h-6 rounded-full ${testMode ? "bg-[#026D77]" : "bg-gray-300"}`}></div>
                  <div className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${testMode ? "transform translate-x-4" : ""}`}></div>
                </div>
                <div className="ml-3 text-gray-700 font-medium flex items-center">
                  Mode test qualité
                  <span className="relative group ml-1" title="Nouvelle fonctionnalité">
                    <HiInformationCircle className="w-4 h-4 text-blue-500" />
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 text-xs text-white bg-gray-700 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition">
                      Information
                    </span>
                  </span>
                </div>
              </label>
            </div>

            {testMode && (
              <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-4">
                <h3 className="text-blue-700 font-semibold mb-2 flex items-center">
                  <HiInformationCircle className="w-5 h-5 mr-1" />
                  Mode test qualité (Bêta)
                </h3>
                <div className="text-sm text-blue-700">
                  <p className="mb-2">Ce mode vous permet de tester la qualité de l'extraction actuelle en comparant les résultats avec les données existantes</p>
                  <p className="mt-2">
                    <span className="font-bold">Important :</span> Seulement les ordonnances téléchargés directement depuis l'explorateur peuvent être testés.
                  </p>
                </div>
              </div>
            )}
          </>
        ) : isRunningTest ? (
          <div className="py-6">
            <h3 className="text-lg font-medium mb-4">Test en cours...</h3>
            <div className="w-full bg-gray-200 rounded-full h-4 mb-2">
              <div className="bg-[#026D77] h-4 rounded-full" style={{ width: `${(testProgress / testTotal) * 100}%` }}></div>
            </div>
            <p className="text-center text-sm text-gray-600">
              {testProgress} / {testTotal} fichiers traités
            </p>
          </div>
        ) : (
          <div className="py-6">
            <h3 className="text-lg font-medium mb-4">Test terminé !</h3>
            <p className="mb-4">Le test a été effectué avec succès. Vous pouvez télécharger les résultats au format CSV.</p>
            <div className="flex justify-center">
              <button onClick={downloadCsv} className="px-4 py-2 bg-[#026D77] text-white rounded-md hover:bg-[#015961]">
                Télécharger le CSV
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const FilterPopup = ({ onClose, onApply, initialQuery = "" }) => {
  const [filterGroups, setFilterGroups] = useState([{ id: 1, conditions: [{ id: 1, category: "code", value: "" }] }]);
  const [savedQueries, setSavedQueries] = useState([]);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [queryName, setQueryName] = useState("");

  const queryToString = useMemo(() => {
    return filterGroups
      .map((group) => {
        return group.conditions
          .map((condition) => {
            const category = FILTER_CATEGORIES.find((cat) => cat.id === condition.category);
            const subFilter = category?.subFilters?.find((sf) => sf.id === condition.subFilter);
            const prefix = subFilter?.value || category?.value || "";
            return `${prefix}${condition.value}`;
          })
          .join(" AND ");
      })
      .join(" OR ");
  }, [filterGroups]);

  useEffect(() => {
    fetchSavedQueries();
  }, []);

  const fetchSavedQueries = async () => {
    try {
      const response = await API.get("/user/saved-queries");
      if (!response.ok) return;

      setSavedQueries(response.data);
    } catch (error) {
      console.error("Error fetching saved queries:", error);
    }
  };

  const saveCurrentQuery = async () => {
    try {
      if (!queryName.trim()) return toast.error("Le nom de la recherche est obligatoire");

      const response = await API.post("/user/saved-queries", { name: queryName, query: queryToString });
      if (!response.ok) return toast.error("Erreur lors de la sauvegarde");

      setSavedQueries(response.data);
      setShowSaveDialog(false);
      setQueryName("");
      toast.success("Recherche sauvegardée");
    } catch (error) {
      console.error("Error saving query:", error);
      toast.error("Erreur lors de la sauvegarde");
    }
  };

  const deleteQuery = async (id) => {
    try {
      const response = await API.delete(`/user/saved-queries/${id}`);
      if (!response.ok) return toast.error("Erreur lors de la suppression");

      setSavedQueries(response.data);
      toast.success("Recherche supprimée");
    } catch (error) {
      console.error("Error deleting query:", error);
      toast.error("Erreur lors de la suppression");
    }
  };

  useEffect(() => {
    if (!initialQuery) return setFilterGroups([{ id: 1, conditions: [{ id: 1, category: "code", value: "" }] }]);

    try {
      const groups = initialQuery.split(" OR ").map((group, groupIndex) => {
        const conditions = group.split(" AND ").map((condition, condIndex) => {
          let matchingCategory = FILTER_CATEGORIES.find((cat) => cat.subFilters?.find((sf) => sf.value && condition.startsWith(sf.value)));
          if (!matchingCategory) matchingCategory = FILTER_CATEGORIES.find((cat) => cat.value && condition.startsWith(cat.value));

          if (!matchingCategory) return { id: condIndex + 1, category: "code", value: condition.trim(), subFilter: "all" };

          let subFilter = matchingCategory.subFilters?.find((sf) => sf.value && condition.startsWith(sf.value));
          let value = condition.replace(subFilter?.value || matchingCategory.value, "").trim();

          return {
            id: condIndex + 1,
            category: matchingCategory.id,
            value,
            subFilter: subFilter?.id || "all",
          };
        });
        return { id: groupIndex + 1, conditions };
      });
      setFilterGroups(groups);
    } catch (error) {
      console.error("Error parsing query:", error);
      setFilterGroups([{ id: 1, conditions: [{ id: 1, category: "code", value: initialQuery, subFilter: "all" }] }]);
    }
  }, [initialQuery]);

  const updateCondition = (groupId, conditionId, field, value) => {
    setFilterGroups(
      filterGroups.map((group) => {
        if (group.id !== groupId) return group;

        return {
          ...group,
          conditions: group.conditions.map((condition) => {
            if (condition.id !== conditionId) return condition;
            return { ...condition, [field]: value };
          }),
        };
      })
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[300]">
      <div className="bg-white rounded-lg p-6 w-[800px] max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Filtres avancés</h2>
          <div className="flex items-center gap-2">
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <HiOutlineX className="w-6 h-6" />
            </button>
          </div>
        </div>

        {savedQueries.length > 0 && (
          <div className="mb-4 p-4 border rounded-lg bg-gray-50">
            <h3 className="font-semibold mb-2 flex items-center gap-2">
              <HiBookmark className="w-5 h-5" />
              Recherches sauvegardées
            </h3>
            <div className="space-y-2 max-h-[80px] overflow-y-auto">
              {savedQueries.map((savedQuery) => (
                <div key={savedQuery.name} className="flex items-center justify-between gap-2 hover:bg-gray-100 rounded">
                  <button
                    className="flex-grow text-left"
                    onClick={() => {
                      onApply(savedQuery.query);
                      onClose();
                    }}
                  >
                    {savedQuery.name}
                  </button>
                  <button onClick={() => deleteQuery(savedQuery._id)} className="text-red-500 hover:text-red-700 mr-4">
                    <HiOutlineX className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="space-y-4">
          {filterGroups.map((group, groupIndex) => (
            <React.Fragment key={group.id}>
              {groupIndex > 0 && (
                <div className="">
                  <span className="font-semibold text-lg">OU</span>
                </div>
              )}
              <div className="border rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-semibold">Groupe {groupIndex + 1}</span>
                  {filterGroups.length > 1 && (
                    <button onClick={() => setFilterGroups(filterGroups.filter((el) => el.id !== group.id))} className="text-red-500 hover:text-red-700">
                      <HiOutlineX className="w-5 h-5" />
                    </button>
                  )}
                </div>

                <div className="space-y-2">
                  {group.conditions.map((condition, condIndex) => (
                    <div key={condition.id} className="flex items-center gap-2">
                      {condIndex > 0 && <span className="text-sm font-medium">ET</span>}
                      <select value={condition.category} onChange={(e) => updateCondition(group.id, condition.id, "category", e.target.value)} className="border rounded-md p-2">
                        {FILTER_CATEGORIES.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.label}
                          </option>
                        ))}
                      </select>
                      {(() => {
                        const category = FILTER_CATEGORIES.find((cat) => cat.id === condition.category);
                        if (category?.options) {
                          return (
                            <select
                              value={condition.value}
                              onChange={(e) => updateCondition(group.id, condition.id, "value", e.target.value)}
                              className="border rounded-md p-2 flex-grow"
                            >
                              <option value="">Sélectionner une option</option>
                              {category.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          );
                        }

                        return (
                          <div className="flex gap-2 flex-grow">
                            <input
                              type="text"
                              value={condition.value || ""}
                              onChange={(e) => {
                                updateCondition(group.id, condition.id, "value", e.target.value);
                              }}
                              placeholder="Les regex sont supportées (.* pour tout inclure)"
                              className="border rounded-md p-2 flex-grow"
                            />
                            {category?.subFilters && (
                              <select
                                value={condition.subFilter || "all"}
                                onChange={(e) => {
                                  const subFilter = category.subFilters.find((sf) => sf.id === e.target.value);
                                  updateCondition(group.id, condition.id, "subFilter", e.target.value);
                                }}
                                className="border rounded-md p-2"
                              >
                                {category.subFilters.map((subFilter) => (
                                  <option key={subFilter.id} value={subFilter.id}>
                                    {subFilter.label}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        );
                      })()}
                      {group.conditions.length > 1 && (
                        <button
                          onClick={() =>
                            setFilterGroups(
                              filterGroups.map((el) => {
                                if (el.id !== group.id) return el;
                                return { ...group, conditions: group.conditions.filter((e) => e.id !== condition.id) };
                              })
                            )
                          }
                          className="text-red-500 hover:text-red-700"
                        >
                          <HiOutlineX className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={() =>
                      setFilterGroups(
                        filterGroups.map((el) => {
                          if (el.id !== group.id) return el;

                          return { ...el, conditions: [...el.conditions, { id: Date.now(), category: "code", value: "" }] };
                        })
                      )
                    }
                    className="text-[#026D77] hover:text-[#015961] text-sm flex items-center gap-1 mt-2"
                  >
                    <HiOutlinePlus className="w-4 h-4" /> Ajouter une condition (ET)
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <button
          onClick={() => setFilterGroups([...filterGroups, { id: Date.now(), conditions: [{ id: Date.now(), category: "code", value: "" }] }])}
          className="text-[#026D77] hover:text-[#015961] flex items-center gap-1 my-4"
        >
          <HiOutlinePlus className="w-5 h-5" /> Ajouter un groupe (OU)
        </button>

        <div className="flex justify-between mt-4">
          <div className="flex items-center gap-2">
            {showSaveDialog ? (
              <div className="flex items-center gap-2 mt-2">
                <input type="text" value={queryName} onChange={(e) => setQueryName(e.target.value)} placeholder="Nom de la recherche" className="border rounded-md p-2 flex-grow" />
                <button
                  onClick={saveCurrentQuery}
                  className="px-4 py-2 bg-[#026D77] text-white rounded-md hover:bg-[#015961] disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!queryName.trim()}
                >
                  <FaRegSave className="w-5 h-5" />
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowSaveDialog(true)}
                className="flex items-center gap-1 text-[#026D77] hover:bg-[#026D77] hover:text-white px-2 py-1 rounded-md"
                title="Sauvegarder la recherche"
              >
                <FaRegSave className="w-5 h-5 mr-1" />
                Sauvegarder
              </button>
            )}
          </div>
          <div className="space-x-2">
            <button onClick={onClose} className="px-4 py-2 border rounded-md hover:bg-gray-100">
              Annuler
            </button>
            <button
              onClick={() => {
                onApply(queryToString);
                onClose();
              }}
              className="px-4 py-2 bg-[#026D77] text-white rounded-md hover:bg-[#015961]"
            >
              Appliquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explorer;
