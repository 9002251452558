import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { HiClipboard, HiOutlineRefresh, HiOutlineTrash, HiKey, HiUsers, HiShieldCheck, HiExclamation } from "react-icons/hi";
import API from "../api";

const Settings = ({ user }) => {
  const isAdmin = user.role === "admin" || user.role === "superadmin";
  const [invite, setInvite] = React.useState({ email: "", admin: false });
  const [disabled, setDisabled] = React.useState(false);
  const [keys, setKeys] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState("keys");

  const inviteUser = async () => {
    setDisabled(true);
    try {
      const res = await API.post("/user/create", invite);
      if (!res.ok) return toast.error(res.message);
      toast.success("L'utilisateur a été invité avec succès");
      setInvite({ email: "", admin: false });
      fetchUsers();
    } catch (error) {
      toast.error(error.message);
    }
    setDisabled(false);
  };

  const fetchKeys = async () => {
    try {
      const res = await API.get("/apikey");
      if (!res.ok) return toast.error(res.message);
      setKeys(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await API.get("/user/all");
      if (!res.ok) return toast.error(res.message);
      setUsers(res.data);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const refreshKeys = async (id, type) => {
    const key = keys.find((key) => key._id === id);
    const confirm = window.confirm(`Voulez-vous vraiment réinitialiser la clé "${key[type]}" ?`);
    if (!confirm) return;

    try {
      const res = await API.post(`/apikey/${id}/refresh/${type}`);
      if (!res.ok) return toast.error(res.message);

      toast.success(`La clé a été réinitialisée avec succès.`);
      fetchKeys();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const toggleKeyStatus = async (id, disabled) => {
    try {
      const action = disabled ? "désactiver" : "activer";
      const confirm = window.confirm(`Voulez-vous vraiment ${action} cette clé d'API ?`);
      if (!confirm) return;

      const res = await API.post(`/apikey/${id}/disable`, { disabled });
      if (!res.ok) return toast.error(res.message);

      toast.success(`La clé a été ${disabled ? "désactivée" : "activée"} avec succès.`);
      fetchKeys();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const sendResetPassword = async (id) => {
    try {
      const confirm = window.confirm("Voulez-vous vraiment envoyer un lien de réinitialisation de mot de passe à cet utilisateur ?");
      if (!confirm) return;

      const res = await API.post(`/user/reset-password/${id}`);
      if (!res.ok) return toast.error(res.message);

      toast.success("Le lien de réinitialisation a été envoyé avec succès");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteUser = async (id) => {
    try {
      const confirm = window.confirm("Voulez-vous vraiment supprimer cet utilisateur ? Cette action est irréversible.");
      if (!confirm) return;

      const res = await API.delete(`/user/${id}`);
      if (!res.ok) return toast.error(res.message || "Une erreur est survenue");

      toast.success("L'utilisateur a été supprimé avec succès");
      fetchUsers();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      fetchKeys();
      fetchUsers();
    }
  }, [isAdmin]);

  const formatDate = (date) => {
    if (!date) return "Jamais";
    const d = new Date(date);
    return d.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div className="px-4 mx-auto">
      {/* Improved Tabs */}
      <div className="mb-8">
        <div className="flex space-x-1 rounded-xl bg-gray-100 p-1 mb-2">
          <button
            onClick={() => setActiveTab("keys")}
            className={`flex items-center justify-center space-x-2 px-4 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 ${
              activeTab === "keys" ? "bg-white text-[#026D77] shadow-sm" : "text-gray-500 hover:text-gray-700 hover:bg-gray-200"
            }`}
          >
            <HiKey className={`h-5 w-5 ${activeTab === "keys" ? "text-[#026D77]" : "text-gray-400"}`} />
            <span>Clés d'API</span>
          </button>
          {isAdmin && (
            <button
              onClick={() => setActiveTab("users")}
              className={`flex items-center justify-center space-x-2 px-4 py-2.5 text-sm font-medium rounded-lg transition-all duration-200 ${
                activeTab === "users" ? "bg-white text-[#026D77] shadow-sm" : "text-gray-500 hover:text-gray-700 hover:bg-gray-200"
              }`}
            >
              <HiUsers className={`h-5 w-5 ${activeTab === "users" ? "text-[#026D77]" : "text-gray-400"}`} />
              <span>Utilisateurs</span>
            </button>
          )}
        </div>
      </div>

      {activeTab === "keys" && (
        <div className="w-full max-w-6xl mx-auto">
          {isAdmin && (
            <div className="w-full">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Clés d'accès API</h2>

              {keys.length === 0 ? (
                <div className="text-center py-10 bg-gray-50 rounded-lg border border-gray-200">
                  <p className="text-gray-500">Aucune clé d'API trouvée</p>
                </div>
              ) : (
                keys.map((key) => (
                  <div key={key._id} className="mb-6 bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden">
                    <div className="px-6 py-4 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                      <h3 className="font-medium text-gray-900">Paire de clés API</h3>
                      <div className="flex items-center gap-2">
                        <span className={`text-sm font-medium ${key.disabled ? "text-red-500" : "text-green-500"}`}>{key.disabled ? "Désactivée" : "Activée"}</span>
                        <button
                          type="button"
                          onClick={() => toggleKeyStatus(key._id, !key.disabled)}
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#026D77] focus:ring-offset-1 ${
                            key.disabled ? "bg-red-500" : "bg-green-500"
                          }`}
                        >
                          <span
                            className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                              !key.disabled ? "translate-x-5" : "translate-x-0"
                            }`}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="p-6 space-y-5">
                      <div className={`${key.disabled ? "opacity-50 pointer-events-none" : ""}`}>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center">
                            <HiShieldCheck className="text-[#026D77] mr-2 h-5 w-5" />
                            <p className="text-sm font-medium text-gray-700">Clé Primaire</p>
                          </div>
                          <button
                            type="button"
                            onClick={() => refreshKeys(key._id, "apikey")}
                            className="text-gray-500 hover:text-[#026D77] transition-colors"
                            title="Réinitialiser cette clé"
                          >
                            <HiOutlineRefresh className="h-5 w-5" />
                          </button>
                        </div>
                        <div className="group relative">
                          <div className="p-3 bg-gray-50 rounded-lg text-sm font-mono break-all flex items-center justify-between border border-gray-200">
                            <span className="truncate pr-2">{key.apikey}</span>
                            <button
                              type="button"
                              className="text-xl shrink-0"
                              onClick={() => {
                                navigator.clipboard.writeText(key.apikey);
                                toast.success("Clé primaire copiée");
                              }}
                            >
                              <HiClipboard className="text-[#026D77] h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className={`${key.disabled ? "opacity-50 pointer-events-none" : ""}`}>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center">
                            <HiShieldCheck className="text-[#026D77] mr-2 h-5 w-5" />
                            <p className="text-sm font-medium text-gray-700">Clé Secondaire</p>
                          </div>
                          <button
                            type="button"
                            onClick={() => refreshKeys(key._id, "apikey2")}
                            className="text-gray-500 hover:text-[#026D77] transition-colors"
                            title="Réinitialiser cette clé"
                          >
                            <HiOutlineRefresh className="h-5 w-5" />
                          </button>
                        </div>
                        <div className="group relative">
                          <div className="p-3 bg-gray-50 rounded-lg text-sm font-mono break-all flex items-center justify-between border border-gray-200">
                            <span className="truncate pr-2">{key.apikey2}</span>
                            <button
                              type="button"
                              className="text-xl shrink-0"
                              onClick={() => {
                                navigator.clipboard.writeText(key.apikey2);
                                toast.success("Clé secondaire copiée");
                              }}
                            >
                              <HiClipboard className="text-[#026D77] h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}

              <div className="mt-6 bg-amber-50 border border-amber-200 rounded-lg p-4 flex">
                <HiExclamation className="h-6 w-6 text-amber-500 mt-0.5 mr-3 shrink-0" />
                <div className="text-sm text-amber-800">
                  <p className="font-medium mb-2">Attention lors de la réinitialisation d'une clé d'accès :</p>
                  <ol className="list-decimal pl-5 space-y-1">
                    <li>L'ancienne clé sera immédiatement désactivée.</li>
                    <li>Une nouvelle clé sera générée pour la remplacer.</li>
                    <li>Toutes les applications utilisant l'ancienne clé perdront l'accès aux services.</li>
                    <li>Assurez-vous de mettre à jour la clé dans tous vos systèmes après une réinitialisation.</li>
                  </ol>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === "users" && isAdmin && (
        <div>
          <h1 className="text-2xl font-bold mb-6 text-gray-800">Gestion des utilisateurs</h1>

          <div className="mb-8 p-6 border border-gray-200 rounded-xl shadow-sm bg-white">
            <h2 className="text-xl font-bold mb-4 text-gray-800">Inviter un utilisateur</h2>
            <div className="flex flex-col md:flex-row gap-4">
              <input
                type="text"
                className="p-3 flex-grow border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#026D77] focus:border-[#026D77] outline-none"
                placeholder="Adresse e-mail"
                value={invite.email}
                onChange={(e) => setInvite({ ...invite, email: e.target.value })}
              />
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => setInvite({ ...invite, admin: !invite.admin })}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#026D77] focus:ring-offset-2 ${
                    invite.admin ? "bg-[#026D77]" : "bg-gray-200"
                  }`}
                >
                  <span
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      invite.admin ? "translate-x-5" : "translate-x-0"
                    }`}
                  />
                </button>
                <span className={`ml-2 ${invite.admin ? "text-[#026D77]" : "text-gray-500"}`}>Administrateur</span>
              </div>
              <button
                type="button"
                disabled={disabled}
                className="md:w-auto w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-5 py-3 bg-[#026D77] text-base font-medium text-white hover:bg-[#025c66] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#026D77] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                onClick={inviteUser}
              >
                Inviter
              </button>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rôle
                    </th>
                    <th scope="col" className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dernière connexion
                    </th>
                    <th scope="col" className="py-3.5 px-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="py-6 text-center text-gray-500">
                        Chargement...
                      </td>
                    </tr>
                  ) : users.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="py-6 text-center text-gray-500">
                        Aucun utilisateur trouvé
                      </td>
                    </tr>
                  ) : (
                    users.map((u) => (
                      <tr key={u._id} className={u._id === user._id ? "bg-blue-50" : "hover:bg-gray-50"}>
                        <td className="py-4 px-4 text-sm font-medium text-gray-900">
                          <span className="flex items-center gap-2">
                            {u.email}
                            {u.email === user.email && <span className="text-gray-500 text-xs">(Vous)</span>}
                          </span>
                        </td>
                        <td className="py-4 px-4">
                          <span
                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                              u.role === "admin" ? "bg-orange-100 text-orange-800" : u.role === "superadmin" ? "bg-purple-100 text-purple-800" : "bg-blue-100 text-blue-800"
                            }`}
                          >
                            {u.role === "admin" ? "Admin" : u.role === "superadmin" ? "Super Admin" : "Utilisateur"}
                          </span>
                        </td>
                        <td className="py-4 px-4 text-sm text-gray-500">{formatDate(u.last_login_at)}</td>
                        <td className="py-4 px-4">
                          {u.email !== user.email && (
                            <div className="flex justify-center gap-3">
                              <button
                                type="button"
                                onClick={() => sendResetPassword(u._id)}
                                className="text-blue-600 hover:bg-blue-50 p-1.5 rounded-md transition-colors"
                                title="Envoyer un lien de réinitialisation de mot de passe"
                              >
                                <HiOutlineRefresh className="h-5 w-5" />
                              </button>
                              {u._id !== user._id && (
                                <button
                                  type="button"
                                  onClick={() => deleteUser(u._id)}
                                  className="text-red-600 hover:bg-red-50 p-1.5 rounded-md transition-colors"
                                  title="Supprimer l'utilisateur"
                                >
                                  <HiOutlineTrash className="h-5 w-5" />
                                </button>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
